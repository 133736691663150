<template>
  <div id="user-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-dialog v-model="isDialogVisible" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveData">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense label="Descripción" type="text" v-model="row.descripcion"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('banner', 1)" />
                  <v-text-field dense v-model="row.foto"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-img :src="row.foto" max-height="200px" alt="logo" contain class="me-3"></v-img>
                </v-col>
                <v-col cols="6" sm="6">
                  <!-- <v-checkbox v-model="banner1" label="Remember me" class="mt-0"></v-checkbox> -->
                  <v-checkbox v-model="banner1" label="Carrusel superior" hide-details></v-checkbox>
                  <!-- <v-img :src="row.foto" max-height="200px" alt="logo" contain class="me-3"></v-img> -->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
            <v-btn type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- list filters -->
    <h2 class="m-2">Banners</h2>
    <v-card>
      <v-btn color="info" class="m-4" @click="showModal(1)">
        <v-icon center>{{ icons.mdiPlusCircle }} </v-icon>
        &nbsp;Banners
      </v-btn>

      <!-- table -->
      <!-- v-model="selectedRows" -->
      <!-- :options.sync="options" -->
      <v-data-table :loading="loading" :headers="tableColumns" :items="items" :server-items-length="totalItems">
        <!-- name -->
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'" size="32">
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(item.fullName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link :to="{ name: 'apps-user-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                {{ item.fullName }}
              </router-link>
              <small>@{{ item.username }}</small>
            </div>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.descripcion`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.descripcion }}</span>
        </template>

        <!-- actions -->

        <template #[`item.acciones`]="{ item }">
          <button class="btn btn-green" @click="showModal(2, item)">
            <v-icon center style="color: white"> {{ icons.mdiPencil }} </v-icon>
          </button>
          &nbsp;
          <button class="btn btn-red" @click="deleteData(item)">
            <v-icon center style="color: white"> {{ icons.mdiDeleteOutline }} </v-icon>
          </button>
          &nbsp;
        </template>

        <!-- <template #[`item.acciones`]="{ item }">
          <v-btn color="success" medium @click="showModal(2, item)">
            <v-icon center dark> {{ icons.mdiPencil }} </v-icon>
          </v-btn>
          &nbsp;
          <v-btn color="error" medium @click="deleteData(item)">
            <v-icon center dark> {{ icons.mdiDeleteOutline }} </v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js';

import { ref } from '@vue/composition-api';

// sidebar
// import UserListAddNew from './UserListAddNew.vue'
// import userStoreModule from '../userStoreModule'

// import useUsersList from './useUsersList'

export default {
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'banners',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Descripción', value: 'descripcion', sortable: true },

        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nuevo banner',
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],
      rolesList: [{ nombre: 'Administrador' }, { nombre: 'Conductor' }, { nombre: 'Empleado' }],
      file: null,
      filename1: null,
      banner1: false,
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    onFileSelected(type, item) {
      this.file1 = event.target.files[0]
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1)
      this.filename1 = type + '_' + this.filename1 + '.jpg'
    },
    async saveData() {
      try {
        //pendiente la validación del form
        // this.formHasErrors = false
        let row = { ...this.row }

        let uploadImg
        //this.$isLoading(true)
        let url_img = ''
        if (this.filename1 != null) {
          uploadImg = await this.$store.dispatch('onFileSelected', { filename: this.filename1, file: this.file1 })
          if (uploadImg == true) {
            console.log('----', uploadImg)
            row.foto = 'https://appyaqui.com/recursosapps/img/' + this.filename1
          }
        }
        let res
        row.banner1 = this.banner1 == true ? 1 : 0
        if (row.id) {
          //es update
          delete row.id
          delete row.calificacion
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nuevo Banner'
        this.row = {}
        this.banner1 = false
      } else {
        this.row = item
        this.banner1 = this.row.banner1 == 1 ? true : false
        this.modalTitle = 'Editar Banner'
      }
      this.isDialogVisible = true
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCommerce() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'comercios/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.commerceList = res
        }
      } catch (error) {
        this.commerceList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

.btn-green {
  color: rgb(255, 255, 255) !important;
  background-color: #20b11b !important;
  border-color: #20b11b !important;
}

.btn-blue {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-red {
  color: #fff !important;
  background-color: #ff0037 !important;
  border-color: #ff0037 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.v-application .primary--text {
  color: black !important; // var(--v-primary-base) !important; */
  caret-color: black !important; // var(--v-primary-base) !important; */
}

// body::-webkit-scrollbar {
//   width: 12px !important;
//   /* width of the entire scrollbar */
// }

// body::-webkit-scrollbar-track {
//   background: orange !important;
//   /* color of the tracking area */
// }

// body::-webkit-scrollbar-thumb {
//   background-color: blue !important;
//   /* color of the scroll thumb */
//   border-radius: 20px !important;
//   /* roundness of the scroll thumb */
//   border: 3px solid orange !important;
//   /* creates padding around scroll thumb */
// }</style>
